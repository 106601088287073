import Vue from 'vue'
import VueRouter from 'vue-router'
import Information from '../views/information.vue'
import Market from '../views/market.vue'
import official from '../views/official.vue'
import informationinfo from '../views/informationinfo.vue'
import mulazimmini from '../views/mulazimmini.vue'
import hediyeug from '../views/hediyeug.vue'
import hediyezh from '../views/hediyezh.vue'
import foodsafety from '../views/foodsafety.vue'
import wangxinban from '../views/wangxinban.vue'
import privacy from '../views/privacy.vue'
import poster from '../views/poster/poster.vue'
import model_2 from '../views/poster/model_2.vue'
import model_3 from '../views/poster/model_3.vue'
import model_5 from '../views/poster/model_5.vue'
import model_4 from '../views/poster/model_4.vue'
import model_6 from '../views/poster/model_6.vue'
import model_7 from '../views/poster/model_7.vue'
import model_9 from '../views/poster/model_9.vue'
import model_8 from '../views/poster/model_8.vue'
import model_10 from '../views/poster/model_10.vue'
import model_14 from '../views/poster/model_14.vue'
import model_11 from '../views/poster/model_11.vue'
import model_12 from '../views/poster/model_12.vue'
import model_13 from '../views/poster/model_13.vue'
import model_15 from "../views/poster/model_15.vue"
import model_16 from "../views/poster/model_16.vue"
import model_17 from "../views/poster/model_17.vue"
import model_18 from "../views/poster/model_18.vue"
import model_19 from "../views/poster/model_19.vue"
import model_20 from "../views/poster/model_20.vue"
import model_21 from "../views/poster/model_21.vue"
import model_22 from "../views/poster/model_22.vue"
import model_23 from "../views/poster/model_23.vue"
import model_24 from "../views/poster/model_24.vue"
import model_25 from "../views/poster/model_25.vue"
import model_26 from "../views/poster/model_26.vue"
import model_27 from "../views/poster/model_27.vue"
import model_28 from "../views/poster/model_28.vue"
import model_29 from "../views/poster/model_29.vue"
import model_30 from "../views/poster/model_30.vue"
import model_31 from "../views/poster/model_31.vue"
import model_32 from "../views/poster/model_32.vue"
import model_33 from "../views/poster/model_33.vue"
import model_34 from "../views/poster/model_34.vue"
import model_35 from "../views/poster/model_35.vue"
import model_36 from "../views/poster/model_36.vue"
import model_37 from "../views/poster/model_37.vue"
import model_38 from "../views/poster/model_38.vue"
import model_39 from "../views/poster/model_39.vue"
import model_40 from "../views/poster/model_40.vue"
import model_41 from "../views/poster/model_41.vue"
import model_42 from "../views/poster/model_42.vue"
import model_43 from "../views/poster/model_43.vue"
import model_44 from "../views/poster/model_44.vue"
import model_45 from "../views/poster/model_45.vue"
import model_46 from "../views/poster/model_46.vue"
import model_47 from "../views/poster/model_47.vue"
import model_48 from "../views/poster/model_48.vue"
import model_49 from "../views/poster/model_49.vue"
import model_50 from "../views/poster/model_50.vue"
import model_51 from "../views/poster/model_51.vue"
import model_52 from "../views/poster/model_52.vue"
import model_53 from "../views/poster/model_53.vue"
import model_54 from "../views/poster/model_54.vue"
import model_55 from "../views/poster/model_55.vue"
import model_56 from "../views/poster/model_56.vue"
import model_57 from "../views/poster/model_57.vue"
import model_58 from "../views/poster/model_58.vue"
import model_59 from "../views/poster/model_59.vue"
import model_60 from "../views/poster/model_60.vue"
import model_61 from "../views/poster/model_61.vue"
import model_62 from "../views/poster/model_62.vue"
import model_63 from "../views/poster/model_63.vue"
import model_64 from "../views/poster/model_64.vue"
import model_65 from "../views/poster/model_65.vue"
import teacherPoster from "../views/gaokaoPoster/index.vue"
import preferential from '../views/preferential.vue'
import institution_client from '../views/institution/Client/index.vue' //规章制度首页
import registration_norms from '../views/institution/Client/registration_norms.vue'//美滋来平台商户入驻协议
import Merchant_residence_agreement from '../views/institution/Client/Merchant_residence_agreement.vue'//入网餐饮服务提供者审查登记规范
import Release_management_norms from '../views/institution/Client/Release_management_norms.vue'//商家信息发布管理规范
import Cheating_management_standard from '../views/institution/Client/Cheating_management_standard.vue'//商户违规作弊管理规范
import Transaction_rules from '../views/institution/Client/Transaction_rules.vue'//美滋来平台交易规则
import User_service_agreement from '../views/institution/Client/User_service_agreement.vue'//美滋来平台用户服务协议
import Protection_of_rights_and_interests from '../views/institution/Client/Protection_of_rights_and_interests.vue'//美滋来平台消费者权益保障措施
import User_evaluation_specification from '../views/institution/Client/User_evaluation_specification.vue'//美滋来平台用户评价规范
import Privacy_policy from '../views/institution/Client/Privacy_policy.vue'//美滋来平台隐私权政策
import Food_safety_management from '../views/institution/Client/Food_safety_management.vue'//美滋来平台食品安全管理办法
import businesslicense from "../views/miniInfo/businesslicense.vue"
import permit from "../views/miniInfo/permit.vue"
import ispprove from "../views/miniInfo/ispprove.vue"
import certificate from "../views/miniInfo/certificate.vue"
import activity from "../views/activity/activity.vue"
import resInfo from "../views/resInfo/index.vue"
import eventDetail from "../views/eventDetails/index.vue"
import businessInfo from "../views/eventDetails/business.vue"
import invitePage from "../views/invitePage.vue"
import celleInfo from "../views/celleInfo.vue"
import celleKaifaInfo from "../views/celleKaifaInfo.vue"
import couponRule from "../views/couponRule.vue"
import foodBusinessLicense from "../views/foodBusinessLicense.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/food-business-license',
    name: 'foodBusinessLicense',
    component: foodBusinessLicense
  },
  {
    path: '/coupon-rule',
    name: 'coupon-rule',
    component: couponRule
  },
  {
    path: '/celle-info',
    name: 'celleInfo',
    component: celleInfo
  },
  {
    path: '/celle-kaifaqu-info',
    name: 'celleInfo',
    component: celleKaifaInfo
  },
  {
    path: '/model_1',
    name: 'model_1',
    component: poster
  },
  {
    path: '/invite',
    name: 'invitePage',
    component: invitePage
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  {
    path: '/event-detail',
    name: 'eventDetail',
    component: eventDetail
  },
  {
    path: '/business-info',
    name: 'businessInfo',
    component: businessInfo
  },
  {
    path: '/res-info',
    name: 'resInfo',
    component: resInfo
  },
  {
    path: '/Transaction_rules',
    name: 'Transaction_rules',
    component: Transaction_rules
  },
  {
    path: '/Merchant_residence_agreement',
    name: 'Merchant_residence_agreement',
    component: Merchant_residence_agreement
  },

  {
    path: '/registration_norms',
    name: 'registration_norms',
    component: registration_norms
  },
  {
    path: '/Release_management_norms',
    name: 'Release_management_norms',
    component: Release_management_norms
  },
  {
    path: '/Cheating_management_standard',
    name: 'Cheating_management_standard',
    component: Cheating_management_standard
  },
  {
    path: '/User_service_agreement',
    name: 'User_service_agreement',
    component: User_service_agreement
  },
  {
    path: '/Protection_of_rights_and_interests',
    name: 'Protection_of_rights_and_interests',
    component: Protection_of_rights_and_interests
  },
  {
    path: '/User_evaluation_specification',
    name: 'User_evaluation_specification',
    component: User_evaluation_specification
  },
  {
    path: '/Privacy_policy',
    name: 'Privacy_policy',
    component: Privacy_policy
  },
  {
    path: '/Food_safety_management',
    name: 'Food_safety_management',
    component: Food_safety_management
  },
  {
    path: '/institution_client',
    name: 'institution_client',
    component: institution_client
  },
  {
    path: '/model_2',
    name: 'model_2',
    component: model_2
  },
  {
    path: '/preferential',
    name: 'preferential',
    component: preferential
  },
  {
    path: '/model_3',
    name: 'model_3',
    component: model_3
  },
  {
    path: '/model_5',
    name: 'model_5',
    component: model_5,
  },
  {
    path: '/model_6',
    name: 'model_6',
    component: model_6
  },
  {
    path: '/model_7',
    name: 'model_7',
    component: model_7
  },
  {
    path: '/model_8',
    name: 'model_8',
    component: model_8
  },
  {
    path: '/model_10',
    name: 'model_10',
    component: model_10
  },
  {
    path: '/model_14',
    name: 'model_14',
    component: model_14
  },
  {
    path: '/model_15',
    name: 'model_15',
    component: model_15
  },
  {
    path: '/model_4',
    name: 'model_4',
    component: model_4
  },
  {
    path: '/model_9',
    name: 'model_9',
    component: model_9
  },
  {
    path: '/model_11',
    name: 'model_11',
    component: model_11
  },
  {
    path: '/model_12',
    name: 'model_12',
    component: model_12
  },
  {
    path: '/model_13',
    name: 'model_13',
    component: model_13
  },
  {
    path: '/model_16',
    name: 'model_16',
    component: model_16
  },
  {
    path: '/model_17',
    name: 'model_17',
    component: model_17
  },
  {
    path: '/model_18',
    name: 'model_18',
    component: model_18
  },
  {
    path: '/model_19',
    name: 'model_19',
    component: model_19
  },
  {
    path: '/model_20',
    name: 'model_20',
    component: model_20
  },
  {
    path: '/model_21',
    name: 'model_21',
    component: model_21
  },
  {
    path: '/model_22',
    name: 'model_22',
    component: model_22
  },
  {
    path: '/model_23',
    name: 'model_23',
    component: model_23
  },
  {
    path: '/model_24',
    name: 'model_24',
    component: model_24
  },
  {
    path: '/model_25',
    name: 'model_25',
    component: model_25
  },
  {
    path: '/model_26',
    name: 'model_26',
    component: model_26
  },
  {
    path: '/model_27',
    name: 'model_27',
    component: model_27
  },
  {
    path: '/model_28',
    name: 'model_28',
    component: model_28
  },
  {
    path: '/model_29',
    name: 'model_29',
    component: model_29
  },
  {
    path: '/model_30',
    name: 'model_30',
    component: model_30
  },
  {
    path: '/model_31',
    name: 'model_31',
    component: model_31
  },
  {
    path: '/model_32',
    name: 'model_32',
    component: model_32
  },
  {
    path: '/model_33',
    name: 'model_33',
    component: model_33
  },
  {
    path: '/model_34',
    name: 'model_34',
    component: model_34
  },
  {
    path: '/model_35',
    name: 'model_35',
    component: model_35
  },
  {
    path: '/model_36',
    name: 'model_36',
    component: model_36
  },
  {
    path: '/model_37',
    name: 'model_37',
    component: model_37
  },
  {
    path: '/model_38',
    name: 'model_38',
    component: model_38
  },
  {
    path: '/model_39',
    name: 'model_39',
    component: model_39
  },
  {
    path: '/model_40',
    name: 'model_40',
    component: model_40
  },
  {
    path: '/model_41',
    name: 'model_41',
    component: model_41
  },
  {
    path: '/model_42',
    name: 'model_42',
    component: model_42
  },
  {
    path: '/model_43',
    name: 'model_43',
    component: model_43
  },
  {
    path: '/model_44',
    name: 'model_44',
    component: model_44
  },
  {
    path: '/model_45',
    name: 'model_45',
    component: model_45
  },
  {
    path: '/model_46',
    name: 'model_46',
    component: model_46
  },
  {
    path: '/model_47',
    name: 'model_47',
    component: model_47
  },
  {
    path: '/model_48',
    name: 'model_48',
    component: model_48
  },
  {
    path: '/model_49',
    name: 'model_49',
    component: model_49
  },
  {
    path: '/model_50',
    name: 'model_50',
    component: model_50
  },
  {
    path: '/model_51',
    name: 'model_51',
    component: model_51
  },
  {
    path: '/model_52',
    name: 'model_52',
    component: model_52
  },
  {
    path: '/model_53',
    name: 'model_53',
    component: model_53
  },
  {
    path: '/model_54',
    name: 'model_54',
    component: model_54
  },
  {
    path: '/model_55',
    name: 'model_55',
    component: model_55
  },
  {
    path: '/model_56',
    name: 'model_56',
    component: model_56
  },
  {
    path: '/model_57',
    name: 'model_57',
    component: model_57
  },
  {
    path: '/model_58',
    name: 'model_58',
    component: model_58
  },
  {
    path: '/model_59',
    name: 'model_59',
    component: model_59
  },
  {
    path: '/model_60',
    name: 'model_60',
    component: model_60
  },
  {
    path: '/model_61',
    name: 'model_61',
    component: model_61
  },
  {
    path: '/model_62',
    name: 'model_62',
    component: model_62
  },
  {
    path: '/model_63',
    name: 'model_63',
    component: model_63
  },
  {
    path: '/model_64',
    name: 'model_64',
    component: model_64
  },
  {
    path: '/model_65',
    name: 'model_65',
    component: model_65
  },
  {
    path: '/teacher-poster',
    name: 'teacherPoster',
    component: teacherPoster
  },
  {
    path: '/information',
    name: 'information',
    component: Information
  },
  {
    path: '/market',
    name: 'market',
    component: Market
  },
  {
    path: '/official',
    name: 'official',
    component: official
  },
  {
    path: '/informationinfo',
    name: 'informationinfo',
    component: informationinfo
  },
  {
    path: '/mini',
    name: 'mini',
    component: mulazimmini
  },
  {
    path: '/hediyeug',
    name: 'hediyeug',
    component: hediyeug
  },
  {
    path: '/hediyezh',
    name: 'hediyezh',
    component: hediyezh
  },
  {
    path: '/foodsafety',
    name: 'foodsafety',
    component: foodsafety
  },
  {
    path: '/wangxinban',
    name: 'wangxinban',
    component: wangxinban
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/businesslicense',
    name: 'businesslicense',
    component: businesslicense
  },
  {
    path: '/permit',
    name: 'permit',
    component: permit
  },
  {
    path: '/ispprove',
    name: 'ispprove',
    component: ispprove
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: certificate
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
